<template>
    <v-container  fluid>
    <v-row dense v-if="this.user_roles_list.flat().includes('employees_write')">
        <v-col lg="8" offset-lg="2">
                <FormHeader v-bind='newEmployeesFormHeader' />
                <v-divider></v-divider>
                

                <!--New Employee form -->
                <v-card>
                <v-form @submit.prevent="saveEmployee" ref="form" v-model="valid">
                    <!--Form field tabs-->
                    <v-tabs
                            background-color="#6666cf"
                            center-active
                            dark
                        >
                        <v-tab>Basic Info</v-tab>
                        <v-tab>Contact Details</v-tab>
                        <v-tab>Bank Details</v-tab>
                    <!--Basic Info tab-->
                    <v-tab-item>
                    <v-container class="pa-3">
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <h4>Basic Info</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="6" md="2">
                                <v-select
                                    v-model="employee_title"
                                    :items="['Mr','Mrs', 'Miss']"
                                    label="Title"
                                    dense
                                    required
                                    outlined
                                    color="#3d2cdd"
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="6" sm="6" md="4">
                                <v-text-field
                                    v-model="employee_firstName"
                                    :rules="[RequireFormInput('first name'), minLength('first name',2), maxLength('first name',50)]"
                                    counter="50"
                                    label="First Name"
                                    dense
                                    required
                                    outlined
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-text-field
                                    v-model="employee_lastName"
                                    :rules="[RequireFormInput('last name'), minLength('last name',2), maxLength('last name',50)]"
                                    counter="50"
                                    label="Last Name"
                                    dense
                                    required
                                    outlined
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="6" md="6">
                                <v-autocomplete
                                    v-model="employee_countryOrigin"
                                    :items="countryList"
                                    append-icon="mdi-magnify"
                                    counter="50"
                                    label="Country of Origin"
                                    dense
                                    outlined
                                    color="#3d2cdd"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-text-field
                                    v-model="employee_idNumber"
                                    :rules="[RequireFormInput('ID or passport number'), minLength('ID or passport number',2), maxLength('ID or passport number',50)]"
                                    counter="50"
                                    label="ID / Passport Number"
                                    dense
                                    required
                                    outlined
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <h4>Employment Specifics</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="6" md="6">
                                <v-autocomplete
                                v-model="employee_jobDescription"
                                    :items="job_descriptions"
                                    label="Job Description"
                                    dense
                                    outlined
                                    color="#3d2cdd"
                                >
                                <!--A button that lets users add new job description
                                if not in select list-->
                                    <template>
                                        <v-list-item slot="append-item">
                                            <v-btn
                                            text
                                            color="#3d2cdd"
                                            to="/new-job-description"
                                            ><v-icon>mdi-plus</v-icon> Add Job Description</v-btn>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-select
                                    v-model="employee_status"
                                    :items="['Employed', 'Terminated', 'Resigned']"
                                    label="Employment Status"
                                    dense
                                    required
                                    outlined
                                    color="#3d2cdd"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-tab-item>

                    <!--Contact Details-->
                    <v-tab-item>
                    <v-container class="pa-3">
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <h4>Contact Details</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="6" md="6">
                                <v-text-field
                                    v-model="employee_cell"
                                    label="Cell Number"
                                    dense
                                    required
                                    outlined
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-text-field
                                    v-model="employee_email"
                                    label="Email"
                                    dense
                                    required
                                    outlined
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <!--Address-->
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <h4>Residential Address</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                    v-model="employee_addr_1"
                                    label="Address Line 1"
                                    append-icon="mdi-map-marker"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                    v-model="employee_addr_2"
                                    label="Address Line 2"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                    v-model="employee_city"
                                    label="City"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                    v-model="employee_zip"
                                    label="Zip Code"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-tab-item>

                    <!--Bank Details tab-->
                    <v-tab-item>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <h4>Bank Details</h4>
                                 </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="employee_bank_name"
                                        label="Bank Name"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="employee_bank_account_number"
                                        label="Account Number"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="employee_bank_branch"
                                        label="Branch Code"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>

                    </v-tabs>
                    <!--End of tabs-->
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/employees'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <v-btn
                        @click="saveEmployee"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading"
                    >
                        Submit
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
    <div v-else>
        <NoPermissionsCard/>
    </div>
</v-container>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'
import firebase from 'firebase'
import store from '../store/store'
import {mapState} from 'vuex'
import FormHeader from '../../../components/Forms/FormHeader'
//Mixins
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import mixin_formValidation from '../mixins/mixin_formValidation'
import mixin_initEmployees from '../mixins/mixin_initEmployees'
import {showSnackbar} from '../../../globalActions/index'
//Components
import NoPermissionsCard from '../../../components/Cards/NoPermissionsCard'
export default {
    name: 'NewEmployee',
    components: {FormHeader, NoPermissionsCard},
    mixins: [
        mixin_UserRoles, mixin_formValidation, mixin_initEmployees
    ],

    data(){
        return{
           loading: false,
           employee_created_by_id: null,
           employee_created_by_name: null,
        }
    },

    //This is where data is retrieved from the Vuex state
    store,
    computed: mapState({
        newEmployeesFormHeader: state => state.newEmployeesFormHeader
    }),

    // Gets job descriptions from firebase datatbase
    created(){
      db.collection('job_descriptions').where('job_description_status', '==', 'Available').get().then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const data = doc.data().job_description_name
          //Pushes the customers name to the empty customers array in 'data' above
          this.job_descriptions.push(data)

        })
      }),
      //Captures user that created the sales order
        this.employee_created_by_id = firebase.auth().currentUser.uid
            db.collection('users').where('uid', '==', this.employee_created_by_id).get().then(querySnapshot => {
            querySnapshot.forEach(doc => {
            this.employee_created_by_name = doc.data().displayName
            })
        })
        //Get employee counter
        db.collection('employee_counter')
        .doc('ZoLv4BWmVSoUSHgCziTy').get().then(snapshot => {
            const count = snapshot.data().employee_count   
            if(count < 10){
                this.employee_number = `EMP-000${count}`  
            }else if (count >= 10 && count < 100){
                this.employee_number = `EMP-00${count}`
            }else if (count >= 100 && count < 1000){
                this.employee_number = `EMP-0${count}`
            }else {
                this.employee_number = `EMP-${count}`
            }     
        })
    },
    methods: {
        //Reroute to employees list after database is updated
        routeToTable(){
            this.loading = false
            showSnackbar("New employee added successfully")
            this.$router.push('/employees')
        },
        //Increment employee number counter
        increaseEmployeeCount(){
            const db = firebase.firestore()
            const increment = firebase.firestore.FieldValue.increment(1)
            const employeeNumberCount = db.collection('employee_counter').doc('ZoLv4BWmVSoUSHgCziTy')
            employeeNumberCount.update({ employee_count: increment })
        },
        saveEmployee () {
            //Form validation --> ensures required fields aren't blank before continuing further
            if(this.$refs.form.validate()){
                this.loading = true
                var newDocRef = db.collection('employees').doc();
                newDocRef.set({
                    //Populated by the v-model values in the form
                    //Sets the category_id == to the document id in database
                    employee_id: newDocRef.id,
                    employee_number: this.employee_number,
                    employee_title: this.employee_title,
                    employee_firstName: this.employee_firstName,
                    employee_lastName: this.employee_lastName,
                    employee_fullName: `${this.employee_firstName} ${this.employee_lastName}`,
                    employee_countryOrigin: this.employee_countryOrigin,
                    employee_idNumber: this.employee_idNumber,
                    employee_jobDescription: this.employee_jobDescription,
                    employee_status: this.employee_status,
                    employee_cell: this.employee_cell,
                    employee_email: this.employee_email,
                    employee_addr_1: this.employee_addr_1,
                    employee_addr_2: this.employee_addr_2,
                    employee_city: this.employee_city,
                    employee_zip: this.employee_zip,
                    employee_bank_name: this.employee_bank_name,
                    employee_bank_account_number: this.employee_bank_account_number,
                    employee_bank_branch: this.employee_bank_branch,
                    employee_date_added: new Date().toISOString().slice(0, 10),
                    employee_timestamp: firebase.firestore.FieldValue.serverTimestamp(),
                    employee_created_by_id: this.employee_created_by_id,
                    employee_created_by_name: this.employee_created_by_id,
                })
                .then(() => {
                    //Increment Work Center number counter
                    this.increaseEmployeeCount()
                    //Reroute to employees list after database is updated
                    this.routeToTable()
                })
            }
        }
    }
}
</script>