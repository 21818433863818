//This JS file is accessed in the Vuex state block
//It is then passed as a function to the parent component 
//Values are then assigned to component propss

//----------------------------------------------
//BUTTONS DATA
//Primary button data
const primaryCTA = {                
    btn_primary_color: '#33cc99',
    btn_plus_icon: 'mdi-plus',
    btn_ml_10: 'ml-10',
}
//Secondary button data
const secondaryCTA = {
    btn_secondary_color: '#e7e7f7',
    btn_ml_10: 'ml-10',
    btn_view_icon: 'mdi-eye',
}

//----------------------------------------------


//EXPORTED to the Vuex state block
export {
    //BUTTONS
    primaryCTA, secondaryCTA
}