<template>
<div>
    <!--Table skeleton loader-->
    <v-row v-if="firstLoad">
    <v-col md="8" offset-md="2">
        <v-sheet elevation="4" class="text-center ma-10 pa-10">
        <v-skeleton-loader :loading="loading_user" type="table" max-height="270">
        </v-skeleton-loader>
        </v-sheet>
    </v-col>
    </v-row>
    <!--End of skeleton loader-->
    <v-container fill-height>
    <v-row v-if="!firstLoad">
        <v-col md="8" offset-md="2">
            <v-sheet elevation="4" class="text-center ma-10 pa-10">
                <v-img
                    style="margin: 0 auto;"
                    src="@/assets/alert.svg"
                    max-width="120"
                ></v-img>
            <h2 class="my-3">Missing Admin Roles</h2>
            <p>You don't have the right admin permissions to use this feature</p>
            <v-btn
                large
                class="mt-3"
                dark
                color="#33cc99"
                to='/'
            >
            <v-icon left small>mdi-arrow-left</v-icon>
                Return To Dashboard
            </v-btn>
            </v-sheet>
        </v-col>
    </v-row>
    </v-container>
</div>
</template>

<script>
    import mixin_UserRoles from '../../globalActions/mixin_UserRoles'
    export default {
        name: 'NoPermissionsCard',
        mixins: [mixin_UserRoles],
        data(){
            return{

            }
        },
        created(){

        }
    }
</script>