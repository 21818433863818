<template>
    <EmployeesTable />
</template>

<script>
import EmployeesTable from '../components/EmployeesTable'
export default {
    name: 'Employees',
    components: {EmployeesTable}
}
</script>