<template>
    <div>
         <v-container fluid>
            <v-row class="mb-8">
                <v-col lg="12">
                    <v-card-title>
                        <h2>Employees</h2>
                        <!--Skeleton button-->
                        <v-skeleton-loader
                          :loading="loading_user"
                          type="button"
                          v-if="firstLoad"
                          class="ml-10"
                        >
                        </v-skeleton-loader>
                        <!--'Add Employee' button below-->
                        <NewEmployeeButton
                          v-if="!firstLoad"
                          v-show="this.user_roles_list.flat().includes('employees_write')"
                        />
                        <ButtonCTA
                            :button_name="viewJobDescriptionsBtn.btn_job_descriptions_name"
                            :button_to="viewJobDescriptionsBtn.btn_job_descriptions_to"
                            :button_color="secondaryCTA.btn_secondary_color"
                            :button_icon="secondaryCTA.btn_view_icon"
                            :button_margin_left="secondaryCTA.btn_ml_10"
                            v-show="this.user_roles_list.flat().includes('employees_read')"
                            v-if="!firstLoad"
                        >
                        </ButtonCTA>
                    </v-card-title>
                </v-col>
            </v-row>
            <!--Table skeleton loader-->
              <v-row v-if="firstLoad">
                <v-col>
                  <v-sheet elevation="4">
                    <v-skeleton-loader :loading="loading_user" type="table" max-height="500">
                    </v-skeleton-loader>
                  </v-sheet>
                </v-col>
              </v-row>
              <!--End of skeleton loader-->
            <v-row v-if="!firstLoad" v-show="this.user_roles_list.flat().includes('employees_read')">
              <v-col>
                <v-sheet elevation="4">
                    <!--Customer data table-->    
                    <template>
                      <div>
                        <v-data-table
                          :headers="employeesTableHeaders"
                          :items="filteredItems"
                          :search="search"
                          sort-by="employee_lastName"
                          :items-per-page="1000"
                          
                        >
                        <template v-slot:top>
                          <v-container fluid>
                            <v-row>
                        <!--Filter products buy availability status--> 
                            <v-col lg="3" md="3">
                              <v-select
                                :items="['Employed','Terminated', 'Resigned']"
                                v-model='employmentStatus'
                                label="Emploment Status"
                                outlined
                                flat
                                solo
                                dense
                                append-icon="mdi-filter-variant"
                              >
                                <template>
                                <v-list-item slot="prepend-item">
                                    <h5>Filter by Employment Status</h5>
                                </v-list-item>
                                </template>
                              </v-select> 
                            </v-col> 
                            <v-spacer />
                            <v-col lg="4" md="4">
                              <v-text-field
                              v-model="search"
                              append-icon="mdi-magnify"
                              label="Search"
                              single-line
                              hide-details
                              clearable
                              ></v-text-field>
                            </v-col> 
                            </v-row>
                          </v-container>
                          </template>
                          <template
                            v-slot:item.employee_id={item}
                            v-if="this.user_roles_list.flat().includes('employees_write')"
                          >
                            <v-btn
                                :to="{ name: 'edit-employee', params: { employee_id: item.employee_id }}"
                                color="#3d2cdd"
                                icon
                                small
                            ><v-icon small>mdi-pencil</v-icon></v-btn>
                        </template>
                        <!--Disable edit button based on user roles-->
                        <template
                          v-slot:item.employee_id
                          v-else
                        >
                          <v-btn
                                color="#3d2cdd"
                                icon
                                small
                                disabled
                            ><v-icon small>mdi-pencil-off</v-icon></v-btn>
                        </template>
                        <!--Empty list-->
                        <template v-slot:no-data>
                          <v-container>
                            <v-row>
                              <v-col>
                                <div class="my-3">
                                  <h3>Welcome To Your Employee List</h3>
                                  <p>
                                      Here you can add new staff members to your system<br>
                                      or view and edit the details of existing ones
                                  </p>
                                  <v-btn
                                    color="#33cc99"
                                    dark
                                    to='/new-employee'
                                    ><v-icon left>mdi-plus</v-icon>Add Employee</v-btn>
                                </div>
                              </v-col>
                            </v-row>
                          </v-container>
                      </template>
                        </v-data-table>
                      </div>
                    </template>
                    </v-sheet>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import db from '../../../components/firebaseInit' //imports firebase database that was specified in firebaseInit.js
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import NewEmployeeButton from '../components/NewEmployeeButton'
import ButtonCTA from '../../../components/Buttons/ButtonCTA'
import store from '../store/store'
import {mapState} from 'vuex'
export default {
    name: 'EmployeesTables',
    mixins: [mixin_UserRoles],
    components: {NewEmployeeButton, ButtonCTA},
    data () {
      return {
        pagination: {},
        search: '',
        employees: [],
        //Used with filtering table by employment status
        employmentStatus: 'Employed',
      }
    },
    // Get employees from firebase datatbase
    created(){
      db.collection('employees').get().then(querySnapshot => {
        this.employees = []
        querySnapshot.forEach(doc => {
          const data = {
            'id': doc.id,
            'employee_id': doc.data().employee_id,
            'employee_number': doc.data().employee_number,
            'employee_firstName': doc.data().employee_firstName,
            'employee_lastName': doc.data().employee_lastName,
            'employee_jobDescription': doc.data().employee_jobDescription,
            'employee_title': doc.data().employee_title,
            'employee_idNumber': doc.data().employee_idNumber,
            'employee_countryOrigin': doc.data().employee_countryOrigin,
            'employee_cell': doc.data().employee_cell,
            'employee_email': doc.data().employee_email,
            'employee_status': doc.data().employee_status,
          }
          //Pushes the employees objects to the empty employees array above
          this.employees.push(data)
        })
      })
    },
    store,
    computed: mapState({
      filteredItems() {
        return this.employees.filter((i) => 
          {
            return this.employmentStatus === '' || (i.employee_status === this.employmentStatus)
          })
        },
        //This is where data is retrieved from the Vuex state
        viewJobDescriptionsBtn: state => state.viewJobDescriptionsBtn,
        secondaryCTA: state => state.secondaryCTA,
        employeesTableHeaders: state => state.employeesTableHeaders
    }),
    methods: {
      formatAvailability (employee_status) {
        if (employee_status == 'Employeed') return '#33cc99'
        else return '#f77f9c'
      },
    }
}
</script>