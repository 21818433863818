//Form vaidation rules
//EditCustomer.vue & NewCustomers.vue

const mixin_formValidation = {
    data(){
        return{
            //Defines rules for form validation --> passes properties and rules bound to input fields
            // Valid property has a v-bind to the submit button and toggles disabled state
            valid: false,
            RequireFormInput(propertyType) {
                return v => v && v.length > 0 || `You must fill in a ${propertyType}`
            },
            minLength(propertyType, minLength){
                return v => v && v.length >= minLength || `The ${propertyType} must be at least ${minLength} characters long`
            },
            maxLength(propertyType, maxLength){
                return v => v.length <= maxLength || `The ${propertyType} can't be more than ${maxLength} characters long`
            }
        }
    }
}

export default mixin_formValidation