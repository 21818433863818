//list of countries imported for dropdown
import countries from'../../../composables/countries'

const mixin_initEmployees = {
    data(){
        return {
            //Initially set all database values to null
            employee_id: null,
            employee_title: null,
            employee_number: null,
            employee_firstName: null,
            employee_lastName: null,
            employee_fullName: null,
            employee_countryOrigin: null,
            employee_idNumber: null,
            employee_jobDescription: null,
            employee_status: 'Employed',             
            employee_cell: null,
            employee_email: null,
            employee_addr_1: null,
            employee_addr_2: null,
            employee_city: null,
            employee_zip: null,
            employee_bank_name: null,
            employee_bank_account_number: null,
            employee_bank_branch: null,
            employee_date_added: null,
            employee_timestamp: null,
            //List of countries imported for dropdown
            countryList: countries,
            job_descriptions: [],
        }
    }
}

export default mixin_initEmployees