import Vue from 'vue';
import Vuex from 'vuex';
//Import component state values
import {
    //BUTTONS
    primaryCTA, secondaryCTA,
} from '../data/data'

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        //----------------------------------------------------------------
        //BUTTONS
        primaryCTA, secondaryCTA,

        //Add job description button data
        addJobDescriptionBtn: {
            btn_add_job_description: 'Add Job Description',
            btn_new_job_description_to: '/new-job-description',
        },
        //View employees button data
        viewEmployeesBtn: {
            btn_employees_name: 'View Employees',
            btn_employees_to: '/employees',
        },
        //View job descriptions button data
        viewJobDescriptionsBtn: {
            btn_job_descriptions_name: 'View Job Descriptions',
            btn_job_descriptions_to: '/job-descriptions',
        },
        //----------------------------------------------------------------
        //DATA TABLE HEADERS
        employeesTableHeaders: [
            { text: 'Edit', value: 'employee_id' },
            { text: 'Employee #', value: 'employee_number'},
            { text: 'First Name', value: 'employee_firstName' },
            { text: 'Last Name', value: 'employee_lastName' },
            { text: 'Title', value: 'employee_title' },
            { text: 'Job Description', value: 'employee_jobDescription' },
            { text: 'ID / Passport', value: 'employee_idNumber' },
            { text: 'Country of Origin', value: 'employee_countryOrigin' },
            { text: 'Cell Number', value: 'employee_cell' },
            { text: 'Email', value: 'employee_email' },
            { text: 'Currently Employed', value: 'employee_status' },
        ],
        jobDescriptionsTableHeaders: [
            { text: 'Edit', value: 'job_description_id' },
            { text: 'Name', value: 'job_description_name' },
            { text: 'Status', value: 'job_description_status' },
            { text: 'Date Added', value: 'job_description_date'}
        ],
        //----------------------------------------------------------------
        //FORM HEADERS
        //Add Employee Form Header
        newEmployeesFormHeader: {
            form_header_title: 'Add New Employee',
            form_header_to: '/employees',
        },
        //Add Job Description Form Header
        newJobDescriptionFormHeader: {
            form_header_title: 'Add New Job Description',
            form_header_to: '/job-descriptions',
        },

        //Edit Employee Form Header
        editEmployeesFormHeader: {
            form_header_title: 'Edit Employee',
            form_header_to: '/employees',
        },
        //Edit Job Description Form Header
        editJobDescriptionFormHeader: {
            form_header_title: 'Edit Job Description',
            form_header_to: '/job-descriptions',
        },
    },
    mutations: {},
    actions: {},
    modules: {},
});