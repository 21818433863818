<template>
    <v-container  fluid>
    <v-row dense>
        <v-col lg="8" offset-lg="2">
                <FormHeader v-bind='editEmployeesFormHeader' />
                <!--New Employee form -->
                <v-card>
                <v-form @submit.prevent="updateEmployee" ref="form" v-model="valid">
                    <!--Form field tabs-->
                    <v-tabs
                            background-color="#6666cf"
                            center-active
                            dark
                        >
                        <v-tab>Basic Info</v-tab>
                        <v-tab>Contact Details</v-tab>
                        <v-tab>Bank Details</v-tab>
                    <!--Basic Info tab-->
                    <v-tab-item>
                    <v-container class="pa-3">
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <h4>Basic Info</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="6" md="2">
                                <v-select
                                    v-model="employee_title"
                                    :items="['Mr','Mrs', 'Miss']"
                                    label="Title"
                                    dense
                                    required
                                    outlined
                                    color="#3d2cdd"
                                >
                                </v-select>
                            </v-col>
                            <v-col cols="6" sm="6" md="4">
                                <v-text-field
                                    v-model="employee_firstName"
                                    :rules="[RequireFormInput('first name'), minLength('first name',2), maxLength('first name',50)]"
                                    counter="50"
                                    label="First Name"
                                    dense
                                    required
                                    outlined
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-text-field
                                    v-model="employee_lastName"
                                    :rules="[RequireFormInput('last name'), minLength('last name',2), maxLength('last name',50)]"
                                    counter="50"
                                    label="Last Name"
                                    dense
                                    required
                                    outlined
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="6" md="6">
                                <v-autocomplete
                                    v-model="employee_countryOrigin"
                                    :items="countryList"
                                    append-icon="mdi-magnify"
                                    counter="50"
                                    label="Country of Origin"
                                    dense
                                    outlined
                                    color="#3d2cdd"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-text-field
                                    v-model="employee_idNumber"
                                    :rules="[RequireFormInput('ID or passport number'), minLength('ID or passport number',2), maxLength('ID or passport number',50)]"
                                    counter="50"
                                    label="ID / Passport Number"
                                    dense
                                    required
                                    outlined
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <h4>Employment Specifics</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="6" md="6">
                                <v-autocomplete
                                v-model="employee_jobDescription"
                                    :items="job_descriptions"
                                    label="Job Description"
                                    dense
                                    outlined
                                    color="#3d2cdd"
                                >
                                <!--A button that lets users add new job description
                                if not in select list-->
                                    <template>
                                        <v-list-item slot="append-item">
                                            <v-btn
                                            text
                                            color="#3d2cdd"
                                            to="/new-job-description"
                                            ><v-icon>mdi-plus</v-icon> Add Job Description</v-btn>
                                        </v-list-item>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-select
                                    v-model="employee_status"
                                    :items="['Employed', 'Terminated', 'Resigned']"
                                    label="Employment Status"
                                    dense
                                    required
                                    outlined
                                    color="#3d2cdd"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-tab-item>

                    <!--Contact Details-->
                    <v-tab-item>
                    <v-container class="pa-3">
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <h4>Contact Details</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" sm="6" md="6">
                                <v-text-field
                                    v-model="employee_cell"
                                    label="Cell Number"
                                    dense
                                    required
                                    outlined
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" sm="6" md="6">
                                <v-text-field
                                    v-model="employee_email"
                                    label="Email"
                                    dense
                                    required
                                    outlined
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <!--Address-->
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <h4>Residential Address</h4>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                    v-model="employee_addr_1"
                                    label="Address Line 1"
                                    append-icon="mdi-map-marker"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                    v-model="employee_addr_2"
                                    label="Address Line 2"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                    v-model="employee_city"
                                    label="City"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                    v-model="employee_zip"
                                    label="Zip Code"
                                    outlined
                                    dense
                                    color="#3d2cdd"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    </v-tab-item>

                    <!--Bank Details tab-->
                    <v-tab-item>
                        <v-container>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <h4>Bank Details</h4>
                                 </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="employee_bank_name"
                                        label="Bank Name"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="employee_bank_account_number"
                                        label="Account Number"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                        v-model="employee_bank_branch"
                                        label="Branch Code"
                                        outlined
                                        dense
                                        color="#3d2cdd"
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-tab-item>

                    </v-tabs>
                    <!--End of tabs-->
                </v-form>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        to='/employees'
                        color="#ce2458"
                        text
                        x-large
                    >
                    Cancel
                    <v-icon right>mdi-close</v-icon>
                    </v-btn>
                    <!--Delete Employee button-->
                    <v-btn
                        @click='deleteEmployee'
                        v-show="this.user_roles_list.flat().includes('employees_delete')"
                        color="#ce2458"
                        text
                        x-large
                        :loading="loading_delete"
                    >
                        Delete
                        <v-icon right>mdi-delete</v-icon>
                    </v-btn>
                    <v-btn
                        @click="updateEmployee"
                        color="#33cc99"
                        text
                        x-large
                        :disabled="!valid"
                        :loading="loading_update"
                    >
                        Update
                        <v-icon right>mdi-check</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</v-container>
</template>

<script>
//Connect to database
import db from '../../../components/firebaseInit'
import FormHeader from '../../../components/Forms/FormHeader'
import store from '../store/store'
import {mapState} from 'vuex'
//Mixins
import mixin_UserRoles from '../../../globalActions/mixin_UserRoles'
import mixin_formValidation from '../mixins/mixin_formValidation'
import mixin_initEmployees from '../mixins/mixin_initEmployees'
import { showSnackbar } from '../../../globalActions/index'

export default {
    name: 'NewEmployee',
    components: {FormHeader},
    mixins: [
        mixin_UserRoles, mixin_formValidation, mixin_initEmployees
    ],

    data(){
        return {
            loading_job_description: false,
            loading_update: false,
            loading_delete: false,
        }
    },
    //Database query that runs before the page loads
    beforeRouteEnter (to, from, next){
        db.collection('employees').where('employee_id', '==', to.params.employee_id).get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc =>{
                //vm assigns all the values we want to put in the template
                next(vm => {
                    //Sets template values to those stored in the database
                    vm.employee_id = doc.data().employee_id,
                    vm.employee_number = doc.data().employee_number,
                    vm.employee_title = doc.data().employee_title,
                    vm.employee_firstName = doc.data().employee_firstName,
                    vm.employee_lastName = doc.data().employee_lastName,
                    vm.employee_fullName = doc.data().employee_fullName,
                    vm.employee_countryOrigin = doc.data().employee_countryOrigin,
                    vm.employee_idNumber = doc.data().employee_idNumber,
                    vm.employee_jobDescription = doc.data().employee_jobDescription,
                    vm.employee_status = doc.data().employee_status,
                    vm.employee_cell = doc.data().employee_cell,
                    vm.employee_email = doc.data().employee_email
                    vm.employee_addr_1 = doc.data().employee_addr_1
                    vm.employee_addr_2 = doc.data().employee_addr_2
                    vm.employee_city = doc.data().employee_city
                    vm.employee_zip = doc.data().employee_zip
                    vm.employee_bank_name = doc.data().employee_bank_name
                    vm.employee_bank_account_number = doc.data().employee_bank_account_number
                    vm.employee_bank_branch = doc.data().employee_bank_branch
                })
            })
        })
    },
    watch: {
        '$route': 'fetchData'
    },
    //This is where data is retrieved from the Vuex state
    store,
    computed: mapState({
        editEmployeesFormHeader: state => state.editEmployeesFormHeader
    }),
    // Gets job descriptions from firebase datatbase
    created(){
    this.loading_job_description = true
    db.collection('job_descriptions').get().then(querySnapshot => {
        querySnapshot.forEach(doc => {
            const data = doc.data().job_description_name
            //Pushes the customers name to the empty customers array in 'data' above
            this.job_descriptions.push(data)
            this.loading_job_description = false
        })
      })
    },
    methods: {
        //Maps database data to template relative to the customer selected in this route
        fetchData () {
        db.collection('employees').where('employee_id', '==', this.$route.params.employee_id).get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            db.employee_id = doc.data().employee_id,
            this.employee_number = doc.data().employee_number,
            this.employee_title = doc.data().employee_title,
            this.employee_firstName = doc.data().employee_firstName,
            this.employee_lastName = doc.data().employee_lastName,
            this.employee_fullName = doc.data().employee_fullName,
            this.employee_countryOrigin = doc.data().employee_countryOrigin,
            this.employee_idNumber = doc.data().employee_idNumber,
            this.employee_jobDescription = doc.data().employee_jobDescription,
            this.employee_status = doc.data().employee_status,
            this.employee_cell = doc.data().employee_cell,
            this.employee_email = doc.data().employee_email
            this.employee_addr_1 = doc.data().employee_addr_1 
            this.employee_addr_2 = doc.data().employee_addr_2 
            this.employee_city = doc.data().employee_city 
            this.employee_zip = doc.data().employee_zip 
            this.employee_bank_name = doc.data().employee_bank_name 
            this.employee_bank_account_number = doc.data().employee_bank_account_number 
            this.employee_bank_branch = doc.data().employee_bank_branch 
          })
        })
      },
      //Updates form values in the database
        updateEmployee () {
            //Form validation --> ensures required fields aren't blank before continuing further
            if(this.$refs.form.validate()){
                this.loading_update = true
                db.collection('employees').where('employee_id', '==', this.$route.params.employee_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.update({
                            employee_id: this.employee_id,
                            employee_number: this.employee_number,
                            employee_title: this.employee_title,
                            employee_firstName: this.employee_firstName,
                            employee_lastName: this.employee_lastName,
                            employee_fullName: (this.employee_firstName + " " + this.employee_lastName),
                            employee_countryOrigin: this.employee_countryOrigin,
                            employee_idNumber: this.employee_idNumber,
                            employee_jobDescription: this.employee_jobDescription,
                            employee_status: this.employee_status,
                            employee_cell: this.employee_cell,
                            employee_email: this.employee_email,
                            employee_addr_1: this.employee_addr_1,
                            employee_addr_2: this.employee_addr_2,
                            employee_city: this.employee_city,
                            employee_zip: this.employee_zip,
                            employee_bank_name: this.employee_bank_name,
                            employee_bank_account_number: this.employee_bank_account_number,
                            employee_bank_branch: this.employee_bank_branch
                        })
                        //Reroute to employee dashboard page after database has been updated
                        .then(() => {
                            this.loading_update = false
                            showSnackbar("Employee info updated successfully");
                            this.$router.push('/employees')
                        })
                    })
                })
            }
        },
        //Removes employee from database
        deleteEmployee () {
            if(confirm('Are you sure you want to delete this employee?')){
                this.loading_delete = true
                db.collection('employees').where('employee_id', '==', this.$route.params.employee_id).get()
                .then(querySnapshot => {
                    querySnapshot.forEach(doc => {
                        doc.ref.delete()
                        //Redirects back to job descriptions list page
                        this.loading_delete = false
                        showSnackbar("Employee deleted successfully");
                        this.$router.push('/employees')
                    })
                }).catch(function(error) {
                    console.error("Error removing document: ", error);
                });
            }
        }
    }
}
</script>